import React from "react"

import Form from "react-bootstrap/Form"
import { FormattedMessage } from "react-intl"

const ExchangeCurrencyWarning = ({ currency, useBelgacoinWallet }) => (
  <>
    {(currency == "BNB" ||
      currency == "SHIB" ||
      currency == "VET" ||
      currency == "USDT" ||
      currency == "BAT" ||
      currency == "ADA" ||
      currency == "SOL" ||
      currency == "DOT" ||
      currency == "USDC" ||
      currency == "UNI" ||
      currency == "LINK" ||
      currency == "ALGO" ||
      currency == "WBTC" ||
      currency == "MATIC" ||
      currency == "XLM" ||
      currency == "ATOM" ||
      currency == "FIL" ||
      currency == "AXS") &&
      !useBelgacoinWallet && (
        <Form.Text className="text-muted">
          <FormattedMessage
            id={
              'This currency is only available with "Use my Belgacoin Wallet".'
            }
          />
        </Form.Text>
      )}
    {(currency == "BNB" ||
      currency == "SHIB" ||
      currency == "VET" ||
      currency == "USDT" ||
      currency == "BAT" ||
      currency == "ADA" ||
      currency == "SOL" ||
      currency == "DOT" ||
      currency == "USDC" ||
      currency == "UNI" ||
      currency == "LINK" ||
      currency == "ALGO" ||
      currency == "WBTC" ||
      currency == "MATIC" ||
      currency == "XLM" ||
      currency == "ATOM" ||
      currency == "FIL" ||
      currency == "AXS") &&
      useBelgacoinWallet && (
        <Form.Text className="text-muted">
          <FormattedMessage id={"This currency is only partially supported."} />
        </Form.Text>
      )}
  </>
)

export default ExchangeCurrencyWarning
