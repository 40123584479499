import React from "react"

import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExchangeNavigation from "../components/exchange_navigation"
import ExchangeSwap from "../components/exchange_swap"

const ExchangeSwapPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Swap" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <ExchangeNavigation location={location} />
      <ExchangeSwap />
    </Layout>
  )
}

export default ExchangeSwapPage
