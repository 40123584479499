import React from "react"

import { FormattedMessage } from "react-intl"
import Space from "./space"

const Mandatory = ({ children }) => {
  return (
    <>
      {children}
      <Space />
      <span className="text-danger">*</span>
    </>
  )
}

export default Mandatory
