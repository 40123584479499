import React from "react"

const ExchangeCurrencies = ({ includeEur }) => (
  <>
    {false && includeEur && <option value="EUR">EUR (**)</option>}
    <option value="ADA">ADA - Cardano (*)</option>
    <option value="ALGO">ALGO - Algorand (*)</option>
    <option value="ATOM">ATOM - Cosmos (*)</option>
    <option value="AXS">AXS - Axie Infinity (*)</option>
    <option value="BAT">BAT - Basic Attention Token (*)</option>
    <option value="BCH">BCH - Bicoin Cash</option>
    <option value="BNB">BNB - Binance Coin (*)</option>
    <option value="BTC">BTC - Bitcoin</option>
    <option value="DOGE">DOGE - Dogecoin</option>
    <option value="DOT">DOT - Polkadot (*)</option>
    <option value="ETH">ETH - Ethereum</option>
    <option value="FIL">FIL - Filecoin (*)</option>
    <option value="LINK">LINK - Chainlink (*)</option>
    <option value="LTC">LTC - Litecoin</option>
    <option value="MATIC">MATIC - Polygon (*)</option>
    <option value="SHIB">SHIB - Shiba Inu (*)</option>
    <option value="SOL">SOL - Solana (*)</option>
    <option value="UNI">UNI - Uniswap (*)</option>
    <option value="USDC">USDC - USD Coin (*)</option>
    <option value="USDT">USDT - Tether (*)</option>
    <option value="VET">VET - VeChain (*)</option>
    <option value="WBTC">WBTC - Wrapped Bitcoin (*)</option>
    <option value="XLM">XLM - Stellar (*)</option>
    <option value="XRP">XRP - Ripple</option>
  </>
)

export default ExchangeCurrencies
