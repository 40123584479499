import React, { Component, Fragment } from "react"

import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import { FormattedMessage } from "react-intl"
import { injectIntl } from "react-intl"

class ExchangeOrderDetails extends Component {
  constructor(props) {
    super(props)
  }

  formatMessage(id) {
    if (id == null) {
      return ""
    }
    return this.props.intl.formatMessage({ id: id })
  }

  render() {
    const intl = this.props.intl
    return (
      <>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Order ID"} />
          </Form.Label>
          <Form.Control type="text" value={this.props.order.key} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Email"} />
          </Form.Label>
          <Form.Control type="email" value={this.props.order.email} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Type of Order"} />
          </Form.Label>
          <Form.Control
            type="text"
            value={this.formatMessage(this.props.order.typeOfOrder)}
            disabled
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Status"} />
          </Form.Label>
          <Form.Control
            type="text"
            value={this.formatMessage(this.props.order.status)}
            disabled
          />
        </Form.Group>
        <h5>
          <FormattedMessage id={"Order Details"} />
        </h5>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Amount 10"} />
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={this.props.order.amount10}
              disabled
            />
            <Form.Control
              type="text"
              value={this.props.order.currency10}
              disabled
              style={{ maxWidth: "75px", textAlign: "center" }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Rate 1"} />
          </Form.Label>
          <Form.Control type="number" value={this.props.order.rate1} disabled />
          <Form.Text className="text-muted">
            <FormattedMessage id={"Rate 1 hint."} />
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Fees 1"} />
          </Form.Label>
          <Form.Control
            type="text"
            value={this.formatMessage(this.props.order.fees1)}
            disabled
          />
        </Form.Group>
        {(this.props.order.typeOfOrder == "Buy" ||
          this.props.order.typeOfOrder == "Swap") && (
          <>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"Network Fees 1"} />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={this.props.order.networkFees1}
                  disabled
                />
                <Form.Control
                  type="text"
                  value={this.props.order.currency11}
                  disabled
                  style={{ maxWidth: "75px", textAlign: "center" }}
                />
              </InputGroup>
              <Form.Text className="text-muted">
                <FormattedMessage id={"Network Fees 1 hint."} />
              </Form.Text>
            </Form.Group>
          </>
        )}
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Amount 11"} />
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={this.props.order.amount11}
              disabled
            />
            <Form.Control
              type="text"
              value={this.props.order.currency11}
              disabled
              style={{ maxWidth: "75px", textAlign: "center" }}
            />
          </InputGroup>
          <Form.Text className="text-muted text-justify">
            <FormattedMessage id={"Amount 11 hint."} />
          </Form.Text>
        </Form.Group>
        <hr />
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Amount 20"} />
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={this.props.order.amount20}
              disabled
            />
            <Form.Control
              type="text"
              value={this.props.order.currency20}
              disabled
              style={{ maxWidth: "75px", textAlign: "center" }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Rate 4"} />
          </Form.Label>
          <Form.Control type="number" value={this.props.order.rate4} disabled />
          <Form.Text className="text-muted">
            <FormattedMessage id={"Rate 4 hint."} />
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Fees 4"} />
          </Form.Label>
          <Form.Control
            type="text"
            value={this.formatMessage(this.props.order.fees4)}
            disabled
          />
        </Form.Group>
        {(this.props.order.typeOfOrder == "Buy" ||
          this.props.order.typeOfOrder == "Swap") && (
          <>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"Network Fees 4"} />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={this.props.order.networkFees4}
                  disabled
                />
                <Form.Control
                  type="text"
                  value={this.props.order.currency41}
                  disabled
                  style={{ maxWidth: "75px", textAlign: "center" }}
                />
              </InputGroup>
              <Form.Text className="text-muted">
                <FormattedMessage id={"Network Fees 4 hint."} />
              </Form.Text>
            </Form.Group>
          </>
        )}
        <Form.Group>
          <Form.Label>
            <FormattedMessage id={"Amount 41"} />
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={this.props.order.amount41}
              disabled
            />
            <Form.Control
              type="text"
              value={this.props.order.currency41}
              disabled
              style={{ maxWidth: "75px", textAlign: "center" }}
            />
          </InputGroup>
          <Form.Text className="text-muted text-justify">
            <FormattedMessage id={"Amount 41 hint."} />
          </Form.Text>
        </Form.Group>
        {this.props.order.methodOfPayment1 == "Standard" && (
          <>
            <h5>
              <FormattedMessage id={"Delivery Details"} />
              <br />
              <small className="text-muted">
                <FormattedMessage id={"Customer to Belgacoin"} />
              </small>
            </h5>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"Address"} />
              </Form.Label>
              <Form.Control
                type="text"
                value={this.props.order.address10}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"TxId"} />
              </Form.Label>
              <Form.Control
                type="text"
                value={this.props.order.txId1}
                disabled
              />
            </Form.Group>
          </>
        )}
        {this.props.order.methodOfPayment2 == "SEPA" && (
          <>
            <h5>
              <FormattedMessage id={"Payment Details"} />
              <br />
              <small className="text-muted">
                <FormattedMessage id={"Belgacoin to Customer"} />
              </small>
            </h5>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"IBAN"} />
              </Form.Label>
              <Form.Control
                type="text"
                value={this.props.order.address20}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"BIC"} />
              </Form.Label>
              <Form.Control
                type="text"
                value={this.props.order.address21}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"Beneficiary"} />
              </Form.Label>
              <Form.Control
                type="text"
                value={this.props.order.address22}
                disabled
              />
            </Form.Group>
          </>
        )}
        {this.props.order.methodOfPayment2 == "Standard" && (
          <>
            <h5>
              <FormattedMessage id={"Delivery Details"} />
              <br />
              <small className="text-muted">
                <FormattedMessage id={"Belgacoin to Customer"} />
              </small>
            </h5>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"Address"} />
              </Form.Label>
              <Form.Control
                type="text"
                value={this.props.order.address20}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"TxId"} />
              </Form.Label>
              <Form.Control
                type="text"
                value={this.props.order.txId2}
                disabled
              />
            </Form.Group>
          </>
        )}
      </>
    )
  }
}

export default injectIntl(ExchangeOrderDetails)
