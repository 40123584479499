import React, { Component, Fragment } from "react"

import FingerprintJS from "@fingerprintjs/fingerprintjs"

import Error from "./error"
import ExchangeOrderDetails from "./exchange_order_details"
import Loading from "./loading"

class ExchangeOrderDetails2 extends Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.state = {
      isLoaded: true, // this.props.order != null
      error: null,
      order: this.props.order,
    }
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 60 * 1000)
    // this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  refresh() {
    /* if (this.state.order == null) {
      return
    } */

    FingerprintJS.load().then(fpAgent => {
      // new FingerprintJS().get(fingerprint => {
      fpAgent.get().then(fpResult => {
        const url = new URL("/exchange/get", process.env.GATSBY_API_URL)
        const params = {}
        params.orderId = this.state.order.key
        params.email = this.state.order.email
        params.fingerprint = fpResult.visitorId
        // params.fingerprint = fingerprint
        Object.keys(params).forEach(key =>
          url.searchParams.append(key, params[key])
        )
        fetch(url, {
          method: "GET",
        })
          .then(response => {
            if (response.ok) {
              response.json().then(json => {
                this.setState({
                  /* isLoaded: true, */ error: null,
                  order: json,
                })
              })
            } else {
              response.text().then(text => {
                this.setState({ /* isLoaded: true, */ error: text })
              })
            }
          })
          .catch(error => {
            this.setState({
              /* isLoaded: true, */
              error: "Error: " + error.message + ".",
            })
          })
      })
    })
  }

  render() {
    const { isLoaded, error, order } = this.state
    if (!isLoaded) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      return <ExchangeOrderDetails order={order} />
    }
  }
}

export default ExchangeOrderDetails2
